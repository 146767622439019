export function useLead() {
  const stateSteps = [
    { level: 0, state: 'reklamiert', task: '' },
    { level: 1, state: 'gebucht', task: 'jetzt offen' },
    { level: 2, state: 'qualifiziert', task: 'jetzt qualifizieren' },
    { level: 3, state: 'beraten', task: 'jetzt beraten' },
    { level: 4, state: 'Angebot versendet', task: 'Angebot erstellen' },
    { level: 5, state: 'Risikovorabgefragt', task: 'Risikovorabfragen' },
    { level: 6, state: 'abgeschlossen', task: 'jetzt abschließen' },
  ]

  // const leadStore = useLeadStore()
  // const { lead } = storeToRefs(leadStore)

  const currentState = (states: State[]) => {
    // Prüfen, ob ein Zustand mit Level 0 existiert
    const levelZeroState = states.find(state => state.level === 0)
    if (levelZeroState) {
      // Wenn ja, diesen Zustand zurückgeben
      return levelZeroState
    }

    // Sonst die bisherige Logik anwenden, um den Zustand mit dem höchsten Level zu finden
    return states.reduce(
      (highest: State, current: State) => current.level > highest.level ? current : highest,
    )
  }

  const canComplain = (lead: Lead) => {
    if (!lead.complaint && lead.complaints?.length === 0)
      return true

    if (lead.complaint === 'cancelled') {
      return lead.complaints.reduce(
        (highest: any, current: any) =>
          current.level > highest.level ? current : highest,
      ).level < 2
    }

    // if (['pending', 'accepted'].includes(lead.complaint as string))
    //   return false

    return false

    // // Prüfen, ob ein Zustand mit Level 0 existiert
    // const levelZeroComplain = complaints.find(complaint => complaint.level === 0)
    // if (levelZeroComplain) {
    //   // Wenn ja, diesen Zustand zurückgeben
    //   return levelZeroComplain
    // }

    // // Sonst die bisherige Logik anwenden, um den Zustand mit dem höchsten Level zu finden
    // return complaints.reduce(
    //   (highest: any, current: any) =>
    //     current.level > highest.level ? current : highest,
    // ).level < 2
  }

  const nextState = (states: State[]) => stateSteps[
    stateSteps.findIndex(step => step.state === (currentState(states).state)) + 1
  ]

  const isNextState = (states: State[]) => {
    // Überprüfen, ob ein Zustand mit Level 0 existiert
    const levelZeroExists = states.some(state => state.level === 0)
    // Den aktuellen Zustand ermitteln
    const current = currentState(states)
    // Überprüfen, ob der aktuelle Zustand 'abgeschlossen' ist
    const isCompleted = current.state === 'abgeschlossen'

    // True zurückgeben, wenn entweder ein Zustand mit Level 0 existiert oder der aktuelle Zustand 'abgeschlossen' ist
    return !levelZeroExists && !isCompleted
  }

  const parseInfos = (infos: string) => {
    const questionConfig = [
      { possibleAnswers: ['KV Kunde wünscht?', 'Private Krankenversicherung '], question: 'KV Kunde wünscht?' },
      { possibleAnswers: ['Kunde wünscht '], question: 'Kunde wünscht' },
      {
        possibleAnswers: ['erfolgreich - Mobil - Telefonica'],
        question: 'Kunde Rufnummern-Ping',
      },
      { possibleAnswers: ['Selbstständige/r'], question: 'Kunde Berufsstatus' },
      {
        possibleAnswers: ['pkv', 'gkv'],
        question: 'KV Bestehende Versicherung',
      },
      {
        possibleAnswers: ['1 Bett-Zimmer', '2 Bett-Zimmer'],
        question: 'KV Krankenhausleistungswunsch',
      },
      {
        possibleAnswers: ['Facharztbehandlung', 'Chefarztbehandlung'],
        question: 'KV Arztversorgungswunsch',
      },
      {
        possibleAnswers: [
          'Zahnleistung sehr wichtig',
          'Zahnleistung wichtig',
          'Zahnleistung unwichtig',
        ],
        question: 'KV Zahnleistungspriorität',
      },
      {
        possibleAnswers: ['keine SB', 'SB 100 €', 'SB 200 €', 'SB 300 €'],
        question: 'KV Selbstbeteiligung gewünscht',
      },
      { possibleAnswers: ['Für Mich'], question: 'KV Für wen' },
      {
        possibleAnswers: ['1 mal pro Jahr', '2 mal pro Jahr', 'gar nicht'],
        question: 'KV Zahnreinigung',
      },
      {
        possibleAnswers: ['besteht', 'besteht nicht'],
        question: 'KV Kieferbehandlung',
      },
      {
        possibleAnswers: ['aktuell bis 1 Jahr', 'aktuell über 3 Jahre'],
        question: 'KV besteht',
      },
      {
        possibleAnswers: [
          'schnellstmöglich starten',
          'zu einem späteren Zeitpunkt starten',
          'KV möglichst zum ',
        ],
        question: 'KV starten',
      },
      { possibleAnswers: ['Rufnummer korrekt'], question: 'Rufnummer' },
      { possibleAnswers: ['phone correct'], question: 'phone' },
      {
        possibleAnswers: ['ja'],
        question: 'Kunde Daten und Rufnummer geprüft',
      },
      {
        possibleAnswers: ['kieferorthopädische Behandlungen '],
        question: 'Kieferorthopädische Behandlungen',
      },
      {
        possibleAnswers: ['Zahnzusatzversicherung '],
        question: 'Zahnzusatzversicherung',
      },
    ]

    const separators = ['; ', ' / ', ' - ', ', ', ':']
    const questionAnswerSeparators = ['?', ': ', '=']

    function extractAnswers(inputString) {
      let answerPairs = []
      let question = ''
      let separator = ''

      for (const sep of separators) {
        if (inputString.includes(sep)) {
          separator = sep
          break
        }
      }

      if (separator)
        answerPairs = inputString.split(separator)

      for (let i = 0; i < answerPairs.length; i++) {
        let answer = answerPairs[i].trim()
        let splitIndex = -1
        for (const qasep of questionAnswerSeparators) {
          const newSplitIndex = answer.lastIndexOf(qasep)
          if (newSplitIndex > splitIndex)
            splitIndex = newSplitIndex
        }

        if (splitIndex !== -1) {
          question = answer.slice(0, splitIndex).trim()
          answer = answer.slice(splitIndex + 1).trim()
          if (question !== '' && answer !== '')
            answerPairs[i] = { answer, question }
        }
        else {
          // console.log(answer)
          const question = getQuestionFromAnswer(answer)
          const answerWithoutQuestion = answer
            .replace(new RegExp(question, 'i'), '')
            .trim()

          answerPairs[i] = {
            answer: answerWithoutQuestion,
            question,
          }
        }
      }

      return answerPairs.filter(
        pair => !!pair.question && !!pair.answer && pair.answer !== '#',
      )
    }

    function getQuestionFromAnswer(answer) {
      for (const config of questionConfig) {
        for (const possibleAnswer of config.possibleAnswers) {
          if (answer.startsWith(possibleAnswer))
            return config.question

          // Fallback: Überprüfen, ob die Antwort den möglichen Antworttext enthält
          else if (answer.includes(possibleAnswer))
            return config.question
        }
      }

      return 'answer in questionConfig not found'
      // return null;
    }

    function processString(inputString) {
      const string = inputString.replace(', details ( ', '; ').replace(')', '')
      const answers = extractAnswers(string)
      const result = []

      for (const [question, answer] of Object.entries(answers))
        result.push({ ...answer })

      if (
        result.some(
          ({ question }) => question === 'answer in questionConfig not found',
        )
      ) {
        console.log('answer in questionConfig not found', result)

        return []
      }

      return result.sort((a, b) => a.question.localeCompare(b.question))
    }

    const uniqueArray = (array) => {
      const uniqueSet = new Set(array.map(item => JSON.stringify(item)))

      return Array.from(uniqueSet).map(item => JSON.parse(item))
    }

    const infosArray = processString(infos.replaceAll('#', '').replace('<br /><br />', '; Google KI Search: '))

    return uniqueArray(infosArray) || infos
  }

  const countCallAttempts = (notes: Note[]) => notes.filter(
    note => note.type === 'callAttempt' && note.text === 'Lead nicht erreicht',
  ).length

  return {
    canComplain,
    countCallAttempts,
    currentState,
    isNextState,
    nextState,
    parseInfos,
  }
}
